// Import Custom Variables
@import "variables.scss";

// Import Bootstrap
@import "lib/bootstrap/scss/bootstrap";

// Import Custom SCSS
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";
@import "navbar.scss";
@import "cards.scss";
@import "login.scss";
@import "footer.scss";

.student-list {
  .inactive {
    background-color: #cccccc !important;
    color: #777777 !important;
  }
}

#class-report-table {
  margin-bottom: 4rem;
}

#section-table-cols {
  .label {
    width: 80px;
  }
  .size {
    width: 40px;
  }
  .grade {
    width: 100%;
  }
}

.section-report-table {
  .cell-edit:hover {
    background-color: #80bdff;
  }
}

.extra_form_action {
  margin-top: 1.5rem;
}

.extra_list_action {
  margin-bottom: 1.5rem;
}

#cell-properties {
  position: absolute;
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
  width: 300px;
  left: -600px;
  top: 0;
  z-index: 100;

  .props-body {
    padding: 12px;
  }
}

#capture-click {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  display: none;
}

.copy-form {
  display: none;
}

.delete_section {
  display: inline-block;
}

#report_config {
  height: 500px;
}

.top-padding { margin-top: 20px; }

.hidden {
  display: none;
}

.att-select {
  margin-top: 20px;
  margin-bottom: 10px;

}

#log_time_out {
  width: 6rem;
}

#from_date.att_report_date, #to_date.att_report_date{
  width: 8em;

}
.att_report_dates .gj-datepicker {
  margin: 0 0.7em 0 0.2em;
}
#att_report_select {
  margin-right: 1em;
}

.report_select {
  margin-bottom: 1em;
}

ul.report_actions {
  margin: 0;
  padding: 0.25rem  0 0 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 0.25rem 0.5rem 0.25rem 0;
  }
}

.table-grade-select {
  margin-left: -8px;
  .dropdown-menu {
    margin-left: -250px;
    padding: 0.5em;
    border-color: #333333;
  }
}
// Spacing Variables
// Change below variable if the height of the navbar changes
$navbar-base-height: 56px;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 225px;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 90px;
// Change below variable to change the height of the sticky footer
$sticky-footer-height: 80px;

$sidebar-bg-color: #dee2e6 !default;